import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Mente" />
    <div className="longtext">
      <h2>Mente Beta</h2>
      <p>
        <a href="itms-services://?action=download-manifest&amp;url=https://www.mente.app/beta-manifest.plist">
          Last ned 2.0 (59)
        </a>
      </p>
    </div>
  </Layout>
)

export default Page
